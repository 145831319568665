import * as React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

const Nav = () => {
  return (
    <div className="relative flex flex-col sm:flex-row items-center justify-between">
      <Link
        to="/"
        className="text-xl font-bold text-slate-800 transition-colors duration-200 transform hover:text-sky-800"
      >
        ツチノコの夢を見ていた
      </Link>
      <ul className="flex items-center space-x-2">
        {
          [["Blog", "/"], ["About", "/about"]].map(
            ([name, to]) => {
              return (
                <li>
                  <Link
                    to={to}
                    className="
                      rounded
                      font-medium text-slate-700
                      hover:bg-slate-600 hover:text-slate-200 transition-colors duration-200
                      px-2 py-1
                    "
                  >
                    {name}
                  </Link>
                </li>
              )
            }
          )
        }
      </ul>
    </div>
  );
};

const Layout: React.FC<{pageTitle: string}> = ({ pageTitle, children }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <>
      <title>{pageTitle}|{data.site.siteMetadata.title}</title>
      <div className="mx-auto my-auto px-4 py-4 max-w-5xl">
        <div className="my-4">
          <Nav />
        </div>
        <main className="text-slate-700">
          <h1 className="my-4 text-3xl font-bold leading-none tracking-tight text-slate-900 sm:my-8 sm:text-4xl">
            {pageTitle}
          </h1>
          {children}
        </main>
      </div>
    </>
  )
}


export default Layout
