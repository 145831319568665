import * as React from 'react'
import Layout from '../components/layout'

const AboutPage = () => {
  return (
    <Layout pageTitle='About Me'>
      <p>
        最近Twitterを眺めていると一日が終るんだけど、どうして...
      </p>
    </Layout>
  )
}

export default AboutPage
